<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Cierre de Kardex</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>


               <b-col md="3">
                  <b-form-group label="Establecimiento:">
                    <b-form-select @change="ListWarehouses" v-model="close_kardex.id_establishment" :options="establishments"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Tipo Comprobante:">
                    <b-form-select v-model="close_kardex.type_invoice" :options="type_invoice"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input class="text-center" type="date" ref="broadcast_date" v-model="close_kardex.broadcast_date"></b-form-input>
                    <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label=".">
                    <b-button class="form-control" @click="CloseKardex" variant="primary">Cerrar Kardex</b-button>
                  </b-form-group>
                </b-col>
          
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <b-modal size="md" class="modal-size" hide-footer v-model="modal_close_kardex" title="LOG DE CIERRE DE KARDEX">
      <b-row>
        <b-col class="text-center" variant="info" md="12" v-for="(item, it) in logs_close_kardex" :key="it">
           <span >{{ item }}</span>
        </b-col>
      </b-row>
    </b-modal>


    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import 'regenerator-runtime/runtime';
import { mapState,mapActions } from "vuex";
import LoadingComponent from './../pages/Loading'
export default {
  name: "UsuarioAdd",
  components:{
      LoadingComponent,
  },
  data() {
    return {
      isLoading:false,
      module: 'CloseKardex',
      role: 2,
      file_excel:null,
      file_excel_name:'',
      close_kardex: {
        id_establishment: "all",
        type_invoice: "NE",
        broadcast_date: moment(new Date()).local().format("YYYY-MM-DD"),
        observation: "",
      },
      establishments : [],
      warehouses:[],
      type_invoice:[
        {value: "NE", text : "Nota de Entrada"},
      ],
      logs_close_kardex:[],
      //errors
      errors: {
        id_establishment: false,
        type_invoice: false,
        broadcast_date: false,
      },
      validate: false,
      modal_close_kardex:false,
    };
  },
  mounted() {
    this.ListEstablishment();
    this.ListWarehouses();
  },
  methods: {
    CloseKardex,
    ListEstablishment,
    ListWarehouses,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListWarehouses() {

let me = this;
let id_establishment = this.close_kardex.id_establishment;
let url = this.url_base + "warehouse/list-warehouse-all/"+id_establishment;

axios({
  method: "GET",
  url: url,
  headers: { token: me.token,module: me.module, role: 3, },
})
  .then(function (response) {
    me.warehouses = [];
    if (response.data.status == 200) {
      let data = response.data.result;
      for (let index = 0; index < data.length; index++) {
        me.warehouses.push( { id_warehouse : data[index].id_warehouse , name: data[index].establishment_name+" - "+data[index].name } );
      }
    }
  })

}

function ListEstablishment() {

  let me = this;
  let url = this.url_base + "active-establishments";

  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,},
  })
    .then(function (response) {
      me.establishments = [{ value: 'all', text:'- Todos -'}];
      if (response.data.status == 200) {
        let data = response.data.result;
        for (let index = 0; index < data.length; index++) {
          me.establishments.push( { value : data[index].id_establishment , text: data[index].name } );
        }
      }
    })
}

async function ClosseKardex() {
  
 

  
  // let url = this.url_base + "close-kardex/"+this.close_kardex.id_establishment+"/"+this.close_kardex.type_invoice+"/"+this.close_kardex.broadcast_date;
  // window.open(url,'_blank');

}

async function CloseKardex() {

  if (this.close_kardex.id_establishment.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un establecimiento', timer: 2000,})
    return false;
  }

  if (this.close_kardex.broadcast_date.length == 0) {
    Swal.fire({ icon: 'warning', text: 'Seleccione un fecha', timer: 2000,})
    return false;
  }

  this.modal_close_kardex = true;
  this.logs_close_kardex = [];

  let me = this;
  const warehouses = this.warehouses;
  console.log('num almacenes : '+warehouses.length)
  this.isLoading = true;
  var index = 1;
  for (const warehouse of warehouses) {
    try {
      me.logs_close_kardex.push("-------------------------------------------------------------------------");
      me.logs_close_kardex.push("INICIANDO - "+warehouse.name);

      console.log("---- INICIANDO - "+warehouse.name+"-----");
      let url = this.url_base + "initial-kardex/close-kardex/"+warehouse.id_warehouse+"/"+this.close_kardex.type_invoice+"/"+this.close_kardex.broadcast_date;
      const response = await axios({
        method: "GET",
        url: url,
        headers: { token: this.token, module: this.module, role: 2},
      }); 
      me.logs_close_kardex.push(response.data.result);
      me.logs_close_kardex.push("-------------------------------------------------------------------------");
      console.log("---- FIN - "+response.data.result+"-----");

       index ++;
    } catch (error) {
      me.logs_close_kardex.push("Error al cerrar kardex:"+ warehouse.name);
      me.logs_close_kardex.push("-------------------------------------------------------------------------");
      console.error("Error al cerrar kardex:"+ warehouse.name); 
    }
  }

  me.isLoading = false;
}
</script>
